<script>
import CurrencyInput from "@/components/CurrencyInput";

export default {
  components: {CurrencyInput},
  props: ["transfer_data","cash_accounts","vatValue","landedCosts","landedCostTypes"],
  data() {
    return {
      tomorrow:null,
      matchedAccounts:[],
      addLandedCost: [{}],
      suggested_gr_time:null,
      suggested_dp_time:null,
      driver:null,
      plate_num:null,
      files: [],
      addPayAccounts: [{}],
    };
  },
  watch: {
    transfer_data(new_value,old_value){
      console.log("transfer_data",this.transfer_data)
      if(old_value?.suggested_gr_time != new_value?.suggested_gr_time && new_value.suggested_gr_time){
        this.suggested_gr_time =this.moment(new_value.suggested_gr_time).format("YYYY-MM-DD")
        console.log("this.suggested_gr_time",this.suggested_gr_time)
      }
      if(old_value?.suggested_dp_time != new_value?.suggested_dp_time && new_value.suggested_dp_time){
        this.suggested_dp_time =this.moment(new_value.suggested_dp_time).format("YYYY-MM-DD")
        console.log("this.suggested_dp_time",this.suggested_dp_time)
      }

      this.driver = new_value.driver
      this.plate_num = new_value.plate_num
    }
  },
  methods: {
    pushFile(event, index) {
      this.addLandedCost[index].has_file = true;
      this.addLandedCost[index].file = event.target.files[0];
      console.log(this.addLandedCost[index].file);
      // this.files.push({ file: event.target.files[0], fIndex: index });
    },
    updateSuggestedTime() {
      this.http
          .put("inventory-transfers", this.transfer_data?.id, {
            suggested_gr_time: this.suggested_gr_time,
            suggested_dp_time: this.suggested_dp_time,
            driver: this.driver,
            plate_num: this.plate_num,
          })
          .then((res) => {
            if (res.status) {
              this.responseAlert.fire(
                  this.$t("popups.updated"),
                  this.$t("popups.done"),
                  "success"
              );
            } else {
              this.responseAlert(
                  this.$t("popups.not_updated"),
                  this.$t("popups.error"),
                  "error"
              );
            }
          });
    },
    saveLandedCost() {

      let filesObject = {};
      for (let i = 0; i < this.addLandedCost.length; i++) {
        if (this.addLandedCost[i].has_file) {
          filesObject["file" + i] = this.addLandedCost[i].file;
        }
      }
      console.log(filesObject);

      this.addLandedCost.forEach((cost) => {
        cost.inventory_transfer_id = this.transfer_data?.id;
      });

      this.http
          .do("inventory-transfers/landed-costs", {
            costData: JSON.stringify(this.addLandedCost),
            accounts: JSON.stringify(this.addPayAccounts),
            id:this.transfer_data?.id,
            ...filesObject,
          })
          .then((res) => {
            if (res.status) {
              this.responseAlert.fire(
                  this.$t("popups.added"),
                  this.$t("popups.done"),
                  "success"
              );
              this.addLandedCost = [{}];
              this.addPayAccounts = [{}];
              this.$emit("onComplete")
            } else {
              this.responseAlert(
                  this.$t("popups.not_added"),
                  this.$t("popups.error"),
                  "error"
              );
            }
          });
    },
    accountSearch(query){
      this.matchedAccounts = this.cash_accounts.filter(account=>
          (
              account.name.toLowerCase().includes(query.toLowerCase()) ||
              account.name_en.toLowerCase().includes(query.toLowerCase())
          ) &&
          !this.addPayAccounts.some(payAccount=>payAccount?.cash_account?.cash_account_id==account.cash_account_id)

      )
      console.log("this.matchedAccounts",this.matchedAccounts)
    },
    validateCashAccount(payAccount){
      if(parseFloat(payAccount.cash_account.balance)==0){
        payAccount.cash_account = ""
      }
    },
    showLabel(account){
      console.log("ACCOUNT",account)
      return (this.$i18n.locale == "ar"
              ? account?.name
              : account?.name_en) + " - " +
          this.processCurrency(account,account.balance)
    }
  },
  computed:{
    validateFullPayment(){
      return this.addLandedCost.every(t=>t.name && t.inventory_transfer_cost_type_id && t.value) && this.totalAddPayAccounts == this.totalLandedCost
    },
    totalLandedCost(){
      return this.addLandedCost.reduce((sum,t)=>sum+t.value,0)
    },
    totalAddPayAccounts(){
      return this.addPayAccounts.filter(account=>account.value)
          .reduce((sum,account)=>{
            console.log("this.cash_accounts.find(a=>a.is_functional)?.id",this.cash_accounts.find(a=>a.is_functional)?.id,"sum",sum,"account",account)
            if(this.cash_accounts.find(a=>a.is_functional)?.id != account.cash_account.currency_id && this.cash_accounts.find(a=>a.is_functional)?.is_functional){
              sum += parseFloat(account.value) * parseFloat(account.cash_account.current_rate)
            }
            else if(this.cash_accounts.find(a=>a.is_functional)?.id !=account.cash_account.currency_id && account.cash_account.is_functional){
              sum += parseFloat(account.value) / parseFloat(this.cash_accounts.find(a=>a.is_functional)?.current_rate)
            }
            else if(this.cash_accounts.find(a=>a.is_functional)?.id != account.cash_account.currency_id){
              sum += parseFloat(account.value) * parseFloat(  this.cash_accounts.find(a=>a.is_functional)?.current_rate) / parseFloat(account.cash_account.current_rate)
            }
            else{
              sum += parseFloat(account.value)
            }
            return sum
          },0)
    }
  },
  mounted() {

  },
};
</script>

<template>
  <!-- start good in transit modal -->

  <div class="modal fade" id="good-in-transit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("purchases.transit.title") }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin: 0"></button>
        </div>

        <div class="modal-body">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <!-- start transit details -->
            <b-tab active>
              <template v-slot:title>
                <div type="button">
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                  <span class="d-none d-sm-inline-block">{{ $t("purchases.transit.details") }}
                    </span>
                </div>
              </template>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <div class="row mb-2">


                          <div class="col-12 col-md-6 mb-1 mb-md-2">
                            <label for="asset_id">{{
                                $t("purchases.inventories")
                              }}</label>
                            <input disabled :value="$i18n.locale == 'ar'
                                ? transfer_data?.dest
                                : transfer_data?.dest_en
                                " type="text" class="form-control" />
                          </div>
                          <div v-if="!transfer_data.dp_time" class="col-12 col-md-6 mb-1 mb-md-2">
                            <label for="asset_id">{{
                                $t("purchases.suggested_dp_time")
                              }}</label>
                            <input v-model="suggested_dp_time" type="date"
                                   class="form-control" />
                          </div>
                          <div class="col-12 col-md-6 mb-1 mb-md-2">
                            <label for="asset_id">{{
                                $t("purchases.suggested_gr_time")
                              }}</label>
                            <input v-model="suggested_gr_time" type="date"
                                   class="form-control" />
                          </div>
                          <!-- Driver -->
                          <div class="mb-3 col-12 col-md-6">
                            <label for="asset_id">{{
                                $t("purchases.driver")
                              }}</label>
                            <input type="text" class="form-control" v-model="driver">
                          </div>

                          <!-- Plate Number -->
                          <div class="mb-3 col-12 col-md-6">
                            <label for="asset_id">{{
                                $t("purchases.plate_num")
                              }}</label>
                            <input type="text" class="form-control" v-model="plate_num">
                          </div>
                        </div>
                        <button class="btn btn-success mb-3 ms-auto" @click="updateSuggestedTime()">{{$t("popups.edit")}}</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end transit details -->
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <div type="button">
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                  <span class="d-none d-sm-inline-block">{{ $t("purchases.transit.landed_cost") }}
                    </span>
                </div>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          <p v-if="addLandedCost.length > 0">
                            {{ $t("purchases.landed_info") }}
                          </p>
                        </div>
                        <div class="col-6">
                          <button type="button" @click="addLandedCost.push({})" class="btn btn-light float-end mb-4">
                            <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                          </button>
                        </div>
                      </div>
                      <div class="my-4" v-for="(cost, index) in addLandedCost" :key="cost">
                        <div class="purchase-container">
                          <div class="d-flex justify-content-between mb-2">
                            <div></div>
                            <button @click="addLandedCost.splice(index, 1)" class="btn btn-danger">
                              <i class="bx bx-trash"></i>
                            </button>
                          </div>
                          <div>
                            <div class="row mb-2">
                              <div class="col">
                                <select v-model="cost.inventory_transfer_cost_type_id" class="form-select">
                                  <option selected disabled>
                                    {{ $t("purchases.type") }}
                                  </option>
                                  <option :value="type?.id" v-for="type in landedCostTypes" :key="type">
                                    {{
                                      $i18n.locale == "ar"
                                          ? type?.name
                                          : type?.name_en
                                    }}
                                  </option>
                                </select>
                              </div>
                              <div class="col">
                                <input v-model="cost.name" type="text" :placeholder="$t('popups.name')"
                                       class="form-control" />
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col">
                                <input type="file" @change="pushFile($event, index)" class="form-control" />
                              </div>
                              <div class="col">
                                <currency-input v-model="cost.value" :placeholder="$t('popups.value')"
                                       class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="addLandedCost.length > 0">
                        <hr />
                        <div>
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <label for="asset_id">{{
                                $t("purchases.chose_acccount")
                              }}</label>
                          </div>
                          <button type="button" @click="addPayAccounts.push({})" class="btn">
                            <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                          </button>
                        </div>
                        <div v-for="(payAccount, aIndex) in addPayAccounts" :key="payAccount">
                          <div class="purchase-container d-flex">
                            <!--                          @item-select="selectSupplier"-->
                            <div class="row w-100">
                              <div class="col-12 col-md-5 mb-2 mb-md-1">
                                <AutoComplete
                                    v-model="payAccount.cash_account"
                                    :suggestions="matchedAccounts"

                                    @item-select="validateCashAccount(payAccount)"
                                    @complete="accountSearch($event.query)"
                                    :complete-on-focus="true"
                                    :optionLabel="showLabel"
                                    style="color: #333 !important;width:100%" />
                              </div>
                              <div class="col-12 col-md-5 mb-2 mb-md-1">
                                <currency-input :disabled="!payAccount.cash_account" v-model="payAccount.value" :max="payAccount?.cash_account?.balance"
                                                class="form-control mb-2 mx-1" :placeholder="$t('purchases.amount')" ></currency-input>
                              </div>
                              <div class="col-12 col-md-2 mb-2 mb-md-1">
                                <button type="button" role="button" @click="addPayAccounts.splice(aIndex, 1)"
                                        :disabled="addPayAccounts.length == 1" class="btn btn-danger">
                                  <i class="bx bx-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6>{{$t("purchases.total")}}: {{totalAddPayAccounts}} / {{processCurrency(cash_accounts.find(a=>a.is_functional),totalLandedCost,true)}}</h6>
                      </div>
                      </div>
                      <div v-if="addLandedCost.length > 0">
                        <hr />
                        <div class="d-flex justify-content-between my-2">
                          <div></div>
                          <button
                              :disabled="totalAddPayAccounts==0 || !validateFullPayment"
                              @click="saveLandedCost()" class="btn btn-primary">
                            {{ $t("popups.done") }}
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-striped table-centered table-nowrap align-middle">
                          <thead>
                          <tr class="text-light" style="background-color: #2a3042 !important">
                            <td>#</td>
                            <td>{{ $t("purchases.type") }}</td>
                            <td>{{ $t("purchases.name") }}</td>
                            <td>{{ $t("purchases.attachment") }}</td>
                            <td>{{ $t("purchases.created") }}</td>
                            <td>{{ $t("popups.value") }}</td>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(cost, index) in landedCosts" :key="cost">
                            <td>{{ index + 1 }}</td>
                            <td>
                              {{
                                $i18n.locale == "ar"
                                    ? cost?.inventory_transfer_cost_type?.name
                                    : cost?.inventory_transfer_cost_type?.name_en
                              }}
                            </td>
                            <td>{{ cost?.name }}</td>
                            <td>
                              <a v-if="cost?.inventory_transfer_documents?.length > 0
                                    " target="_blank" :href="`${$store.state.filesUrl}${cost?.inventory_transfer_documents[0]?.file?.split(
    '/public/'
  )[1]
    }`" download="">{{ $t("emp_files.download") }}</a>
                            </td>
                            <td>{{ new Date(cost?.created).toLocaleString("en-GB") }}</td>
                            <td>{{ processCurrency(this.cash_accounts?.find(a=>a.is_functional),cost?.value) }}</td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>
                            <td>{{$t("purchases.total")}}</td>
                            <td>
                              <b>
                                {{processCurrency(this.cash_accounts?.find(a=>a.is_functional),landedCosts?.reduce((sum,t)=>sum+Math.round(t.value),0))}}
                              </b>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
  <!-- end good in transit modal -->
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
