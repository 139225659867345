<script>
import Layout from "../../../layouts/main";

import IncomingItems from '../IncomingItems/index.vue'
import StockPage from '../StockPage/index.vue'
import OutgoingItems from "@/router/views/Inventories/outgoingItems";
export default {
    page: {
        meta: [
            {
                name: "description",

            },
        ],
    },
    components: {
        Layout,
        IncomingItems,
      OutgoingItems,
      StockPage,
    },
    data() {
        return {
            inventory:{},
            methods:{},
            methods2:{},
        };
    },
    computed: {

    },
    methods: {
      getInventoryInfo(){
        this.http.get("inventories/"+this.$route.params.id)
            .then(resp=>{
              this.inventory = resp.data
            })
      }
    },
    async created() {
        this.getInventoryInfo()
    },
};
</script>

<template>
    <Layout>
      <div class="row">
        <div class="row align-items-center">
          <div class="d-flex align-items-center mb-4">
            <router-link :to="`/inventories`">
              <h4 class="m-0">{{ $t('menu.menuitems.inventories.text') }}</h4>
            </router-link>
            <div class="mx-2">\</div>
            <div>
              {{
                $i18n.locale == 'ar' ? inventory?.name :inventory?.name_en
              }}
            </div>
          </div>
        </div>
      </div>
        <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
            nav-class="justify-content-start nav-tabs-custom">
            <b-tab>
              <template v-slot:title>
                <div @click="methods.get_stock(1)" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
                  <span class="d-none d-sm-inline-block"
                  >{{ $t('inventories.incoming_full.Stock') }}
            </span>
                </div>
              </template>
                <stock-page :methods="methods" />
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <div @click="methods.get_incoming(1)" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
                  <span class="d-none d-sm-inline-block"
                  >{{ $t('inventories.incoming_full.incoming') }}
            </span>
                </div>
              </template>
                <incoming-items :methods="methods" />
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <div @click="methods.get_outgoing(1)" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
                  <span class="d-none d-sm-inline-block"
                  >{{ $t('inventories.incoming_full.outgoing') }}
            </span>
                </div>
              </template>
              <outgoing-items :methods="methods" />
            </b-tab>


    </b-tabs>
</Layout></template>
