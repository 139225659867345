<script>

import {Dropdown, Modal} from 'bootstrap'
import GoodInTransit from "@/router/views/Inventories/outgoingItems/GoodInTransit";
export default {
  page: {
    meta: [
      {
        name: "description",

      },
    ],
  },
  props: ["methods"],
  components: {
    GoodInTransit
  },
  data() {
    return {
      filter: "outgoing",
      detailModal: '',
      good_dispatched_modal: '',
      good_in_transit_modal: '',
      transfer_order:{},
      page: 1,
      limit: 20,
      tot_pages: 0,
      dropdown: null,
      searchModel: "",
      landedCosts: [],
      landedCostTypes: [],
      cash_accounts: [],
      outgoing_items: [],
      items: [],
      selectedItem:null,
    };
  },
  methods: {
    deleteTransferOrder(id) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("inventory-transfers", id).then(() => {
            this.get(this.page);
          });
        }
      });
    },

    getLandedCosts() {
      return new Promise(resolve => {
        this.http
            .post("inventories/get-landed-costs", {
              id: this.transfer_order?.id,
            })
            .then((res) => {
              if (res.status) {
                res.data.sort((a,b)=>b.id - a.id)
                this.landedCosts = res.data;
                this.landedCostTypes = res.landed_cost_types;
                this.cash_accounts = res.cash_accounts;

              }
              resolve()
            });
      })

    },
    async preGoodInTransit(app){
      this.transfer_order = app
      console.log(" this.transfer_order ", this.transfer_order )
      await this.getLandedCosts()
      this.good_in_transit_modal.show()
    },
    async showDetails(app) {
      this.selectedItem = Object.assign({},app)
      this.items = await this.getDetails(app.type,app.id)
      this.detailModal.show();

      // this.popup.modal(data);
    },
    async preGoodDispatched(app) {
      this.selectedItem = app
      this.items = await this.getDetails(app.type,app.id)
      this.good_dispatched_modal.show();
      console.log("this.items",this.items)
    },
    getDetails(type,id){
      return new Promise(resolve => {
        this.http.post("inventories/get-outgoing-details",{
          type,
          id
        }).then(resp=>{
          this.selectedItem.currency = resp.currency
          this.selectedItem.functional_currency = resp.functional_currency
          resolve(resp.data)
        })
      })
    },
    goodDispatched(){
      console.log("items",this.items)
      console.log("selectedItem",this.selectedItem)
      this.http.post("inventories/good-dispatched",{
        order:{
          id : this.selectedItem.id,
          type : this.selectedItem.type,
        },
        items:this.items.map(item=>{
          return {
            id:item.id,
            qty:parseFloat(item.qty),
            unit_price:parseFloat(item.unit_price),
          }
        })
      })
          .then(()=>{
            this.get(this.page)
            this.good_dispatched_modal.hide()
          })
    },
    get(page) {
      console.log(page);
      this.http
          .post("inventories/outgoing-items", {
            filter: this.filter,
            search: (this.searchModel ? this.searchModel : ""),
            limit: this.limit,
            page,
            inventory_id: this.$route.params.id
          })
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.outgoing_items = res.data;
          });
    },
  },
  computed: {

  },
  mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    this.methods.get_outgoing = this.get
    this.dropdown = new Dropdown(".dropdown-toggle.filter-outgoing")
    this.good_dispatched_modal = new Modal(`#good-dispatched`)
    this.good_in_transit_modal = new Modal(`#good-in-transit`)
    this.detailModal = new Modal(`#outgoing-good-details`)
    this.get(1);

  },

  created() {

  }
};
</script>

<template>

  <div class="d-flex justify-content-between">
    <div class="search-box chat-search-box w-25">
      <div class="position-relative">
        <input @input="get(1)" type="search" class="form-control fa-lg text-light"
               :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
               style="background-color: #2a3042 !important" />
        <i class="bx bx-search-alt search-icon text-light"></i>
      </div>
    </div>

    <div class="d-flex">
      <div class="">
        <div>
          <div class="position-relative">
            <input type="text" :value="$t(('inventories.'+filter))" class="form-control" readonly>
          </div>
        </div>
      </div>
      <div class="">
        <div class="dropdown">
          <button class="btn btn-light dropdown-toggle filter-outgoing" @click="dropdown.toggle" data-bs-toggle="dropdown" >
            <span class="fa fa-filter"></span>
          </button>
          <ul class="dropdown-menu" style="z-index:100">
            <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='all'; get(page)">{{$t("inventories.all")}}</a></li>
            <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='outgoing'; get(page)">{{$t("inventories.outgoing")}}</a></li>
            <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='dispatched'; get(page)">{{$t("inventories.dispatched")}}</a></li>
            <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='sales_only'; get(page)">{{$t("inventories.sales_only")}}</a></li>
            <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='inventory_transfers_only'; get(page)">{{$t("inventories.inventory_transfers_only")}}</a></li>
            <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='debit_notes_only'; get(page)">{{$t("inventories.debit_notes_only")}}</a></li>
            <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='exceeded_schedule_only'; get(page)">{{$t("inventories.exceeded_schedule_only")}}</a></li>
          </ul>
        </div>
      </div>
    </div>

  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
    <thead>
    <tr class="text-light text-center" style="background-color: #2a3042 !important">
      <th scope="col">
        {{ $t("inventories.incoming_full.number") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.code") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.type") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.destination") }}
      </th>
      <th scope="col">
        {{ $t("purchases.driver") }}
      </th>
      <th scope="col">
        {{ $t("purchases.plate_num") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.scheduled_time") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.dispatch_time") }}
      </th>
      <th scope="col">
        {{ $t("purchases.created") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.actions") }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(app, index) in outgoing_items" :key="app" class="text-center">

      <td>{{ (index+1) }}</td>
      <td>{{ app?.code }}</td>
      <td>{{ $t("inventories."+app?.type) }}</td>
      <td>{{ $i18n.locale == "ar" ? app?.dest : app?.dest_en }}</td>
      <td>{{ app?.driver }}</td>
      <td>{{ app?.plate_num }}</td>
      <td>{{ app?.suggested_dp_time ? moment(app?.suggested_dp_time).format("YYYY-MM-DD") : $t("inventories.incoming_full.no_scheduled_time") }}</td>
      <td>{{ app?.dp_time ? moment(app?.dp_time).format("YYYY-MM-DD") : $t("inventories.incoming_full.not_dispatched") }}</td>
      <td :title="moment(app?.created).format('h:mm a')">{{ moment(app?.created).format("YYYY-MM-DD") }}</td>
      <td class="d-flex justify-content-start">


        <button  @click="showDetails(app)"
                 class="btn btn-info mx-1"
                 href="javascript: void(0);" role="button">
          <i class="fa fa-list text-xl" style="font-size: 16px"></i>
        </button>

        <button  v-if="!app?.dp_time" @click="preGoodDispatched(app)"
                 class="btn btn-primary mx-1"
                href="javascript: void(0);" role="button">
          <i class="fa fa-plane-departure text-xl" style="font-size: 16px"></i>
        </button>
        <button v-if="app?.type=='transfer' && !app?.gr_time" @click="
                          preGoodInTransit(app);
                        " class="btn btn-primary mx-1"
                href="javascript: void(0);" role="button">
          <i class="fa fa-truck text-xl" style="font-size: 16px"></i>
        </button>
        <button v-if="app?.type=='transfer' && !app?.dp_time && app?.deletable" @click="deleteTransferOrder(app.id)" class="btn btn-danger mx-1"
                href="javascript: void(0);" role="button">
          <i class="bx bx-trash text-xl" style="font-size: 16px"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages> 1">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>



 <!-- Modals -->
  <good-in-transit @on-complete="getLandedCosts();get(page)" :transfer_data="transfer_order"  :cash_accounts="cash_accounts" :landed-costs="landedCosts" :landed-cost-types="landedCostTypes"></good-in-transit>

  <div class="modal fade" id="good-dispatched" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog" style="max-width: 1024px">
      <div class="modal-content">
        <form @submit.prevent="goodDispatched()">

          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("inventories.incoming_full.order_details.page_title") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    style="margin: 0">

            </button>
          </div>

          <div class="modal-body">

            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-2">
              <thead>
              <tr class="text-light" style="background-color: #2a3042 !important">
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.no") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.product") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.quantity") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(detail, index) in items" :key="detail">
                <td>{{ index + 1 }}</td>
                <td>{{ $i18n.locale == "ar"
                    ? detail?.name
                    : detail?.name_en }}</td>
                <td>{{ parseFloat(detail.qty) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <button class="btn btn-primary mx-1" type="submit" href="javascript: void(0);">
              {{
                $t("inventories.incoming_full.dp") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="outgoing-good-details" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog" style="max-width: 1024px">
      <div class="modal-content">

        <div class="modal-header pt-4 px-4">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("inventories.incoming_full.order_details.page_title") }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  style="margin: 0">

          </button>
        </div>

        <div class="modal-body">

          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-2">
            <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">
                {{ $t("inventories.incoming_full.order_details.no") }}
              </th>
              <th scope="col">
                {{ $t("inventories.incoming_full.order_details.product") }}
              </th>
              <th scope="col">
                {{ $t("inventories.incoming_full.order_details.quantity") }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(detail, index) in items" :key="detail">
              <td>{{ index + 1 }}</td>
              <td>{{ $i18n.locale == "ar"
                  ? detail?.name
                  : detail?.name_en }}</td>
              <td>{{ parseFloat(detail?.qty) }}</td>
            </tr>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>

</template>

<style scoped>
.form-check{
  text-align:unset !important;
}
.form-check .form-check-input{
  float:unset !important;

}
[dir=rtl] .form-check .form-check-input{
  margin-left: .5em;
}
[dir=ltr] .form-check .form-check-input{
  margin-right: .5em;
}
</style>
