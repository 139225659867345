<script>

import { Dropdown,Modal } from 'bootstrap'

export default {
    page: {
        meta: [
            {
                name: "description",

            },
        ],
    },
    props: ["methods"],

    components: {

    },
    data() {
        return {
          stock: [],
          other_inventories: [],
          matchedProducts: [],
          transfer_items: [{}],
            filter:  'all',
          to_inventory_id:null,
          suggested_gr_time:null,
          suggested_dp_time:null,
          driver:null,
          plate_num:null,
          dropdown:null,
          reviewModal:null,
          transferModal:null,
            page: 1,
            limit: 20,
            tot_pages: 0,
            stockSearchMode: false,
            searchModel: "",
        };
    },
    methods: {
      doTransferRequest(){
        this.http
            .post("inventories/start-transfer", {
              to_inventory_id: this.to_inventory_id,
              from_inventory_id: this.$route.params.id,
              suggested_dp_time: this.suggested_dp_time,
              suggested_gr_time: this.suggested_gr_time,
              driver: this.driver,
              plate_num: this.plate_num,
              transfer_items: this.transfer_items,
            })
            .then(() => {
              this.transferModal.hide()
              this.get(this.page)
            });
      },
      editInventoryProduct(app) {
        var data = {
          title: "inventories.threshold_stock",
          inputs:[{
            "model":"threshold_qty",
            "label":"",
            "type":"number",
            "value":app?.threshold_qty
          }],
          buttons: [
            {
              text: "popups.edit",
              closer: true,
              color: "success",
              handler: (obj) => {
                this.http.put("inventory-products", app.id,obj).then(() => {
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "light",
              handler: () => {

              },
            },
          ],
        };
        this.popup.modal(data)

      },
        search(search) {
            this.stockSearchMode = true;
            if(!search)
              search = this.searchModel
            this.tot_pages = 0;
            this.http
                .post("inventories/stock/search", {
                    search,
                    limit: this.limit,
                    page: this.page,
                })
                .then((res) => {
                  this.searchModel = search
                    this.stock = res.data;
                });
        },
        get(page) {
            console.log(page);
            this.http
                .post("inventories/stock", {
                    limit: this.limit,
                    page,
                    filter:this.filter,
                    inventory_id: this.$route.params.id,
                })
                .then((res) => {
                    this.page = page;
                    this.tot_pages = Math.ceil(res.tot / this.limit);
                    this.stock = res.data
                    this.other_inventories = res.other_inventories
                });
        },
      searchProduct(text){
        this.matchedProducts = this.stock.filter(item=>!this.transfer_items.find(i=>i?.product?.id == item.id) && (item.name.toLowerCase().includes(text.toLowerCase()) || item.name_en.toLowerCase().includes(text.toLowerCase())))
      },
      showProductsLabel(one) {
        return this.$i18n.locale == 'ar'? one.name : one.name_en;
      },
    },
  computed() {

  },
    mounted() {
      // eslint-disable-next-line vue/no-mutating-props
      this.methods.get_stock = this.get
      this.transferModal = new Modal("#transfer-modal")
      this.reviewModal = new Modal("#review-modal")
      this.get(1);
      this.dropdown = new Dropdown(".dropdown-toggle.filter-stock")
    },


};
</script>

<template>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary me-1" v-if="stock.length>0" :title="$t('inventories.review')" @click="reviewModal.show()">
            <span class="fa fa-list-check"></span>
          </button>
          <button class="btn btn-primary me-1" v-if="stock.length>0" :title="$t('inventories.transfer')" @click="transferModal.show()">
            <span class="fa fa-right-left"></span>
          </button>
            <div class="">
                <div>
                    <div class="position-relative">
                        <input type="text" :value="$t(('inventories.'+filter))" class="form-control" readonly>
                    </div>
                </div>
            </div>
          <div class="">
            <div class="dropdown">
            <button class="btn btn-light dropdown-toggle filter-stock" @click="dropdown.toggle" data-bs-toggle="dropdown" >
              <span class="fa fa-filter"></span>
            </button>
            <ul class="dropdown-menu" style="z-index:100">
              <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='all'; get(page)">{{$t("inventories.all")}}</a></li>
              <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='available_only'; get(page)">{{$t("inventories.available_only")}}</a></li>
              <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='not_available'; get(page)">{{$t("inventories.not_available")}}</a></li>
              <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='warning'; get(page)">{{$t("inventories.warning")}}</a></li>
            </ul>
            </div>
          </div>

        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
            <thead>
                <tr class="text-light text-center" style="background-color: #2a3042 !important">
                    <th scope="col">
                        {{ $t("inventories.id") }}
                    </th>
                    <th scope="col">
                        {{ $t("purchases.item_name") }}
                    </th>
                  <th scope="col">
                    {{ $t("products.unit") }}
                  </th>
                    <th scope="col">
                        {{ $t("inventories.threshold_stock") }}
                    </th>
                  <th scope="col">
                    {{ $t("inventories.on_hand") }}
                  </th>
                  <th scope="col">
                    {{ $t("inventories.free_to_use") }}
                  </th>
                    <th scope="col">
                        {{ $t("inventories.incoming") }}
                    </th>

                    <th scope="col">
                        {{ $t("inventories.outgoing") }}

                    </th>

                    <th scope="col">
                        {{ $t("inventories.incoming_full.actions") }}

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(app, index) in stock" :key="app" class="text-center">

                    <td>{{ (index+1) }}</td>
                    <td>{{ $i18n.locale == 'ar' ? app?.name : app?.name_en }}</td>
                    <td>{{ app?.unit }}</td>
                    <td>{{ app?.threshold_qty ? parseFloat(app?.threshold_qty) : "∞" }}</td>

                  <td>{{ parseFloat(app?.qty) }}</td>
                  <td>{{ parseFloat(app?.qty) - parseFloat(app?.outgoing_sale) - parseFloat(app?.outgoing_transfer) - parseFloat(app?.outgoing_purchase_return)}}</td>

                 <td>{{ parseFloat(app?.incoming_purchase) + parseFloat(app?.incoming_sale_return) + parseFloat(app?.incoming_transfer)  }}</td>

                  <td>{{  parseFloat(app?.outgoing_sale) + parseFloat(app?.outgoing_transfer) + parseFloat(app?.outgoing_purchase_return)}}</td>

                    <td class="d-flex justify-content-end">

                        <a class="btn btn-primary" @click="editInventoryProduct(app)" href="javascript: void(0);" role="button">
                            {{
                                $t("popups.edit") }}
                        </a>



                    </td>
                </tr>
            </tbody>
        </table>

        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="tot_pages> 1">
            <!--   Apps  Pagination     -->
            <ul class="pagination pagination-rounded justify-content-center mb-2">
                <li class="page-item" :class="{ disabled: page == 1 }">
                    <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
                        <i class="mdi mdi-chevron-left"></i>
                    </a>
                </li>
                <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                    <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
                        p
                    }}</a>
                </li>

                <li class="page-item" :class="{ disabled: page == total_pages }">
                    <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
                        <i class="mdi mdi-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </div>

        <!--end-->

 <!--   Transfer Modal   -->
  <div class="modal fade" id="transfer-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="doTransferRequest()">

          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" style="width:100%" id="exampleModalLabel">
              {{ $t("inventories.transfer") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    style="margin: 0">

            </button>
          </div>

          <div class="modal-body">
            <!-- Inventory -->

            <div class="row">
              <div class="mb-3 col-12 col-md-6">
                <label for="asset_id">{{
                    $t("purchases.inventories")
                  }}</label>
                <select required v-model="to_inventory_id" class="form-select mb-2">
                  <option v-for="item in other_inventories" :key="item" :value="item.id">
                    {{ $i18n.locale == 'ar' ? item?.name : item?.name_en }}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-12 col-md-6">
                <label for="asset_id">{{
                    $t("purchases.suggested_dp_time")
                  }}</label>
                <input type="date" class="form-control" v-model="suggested_dp_time">
              </div>
              <div class="mb-3 col-12 col-md-6">
                <label for="asset_id">{{
                    $t("purchases.suggested_gr_time")
                  }}</label>
                <input type="date" class="form-control" v-model="suggested_gr_time">
              </div>

              <!-- Driver -->
              <div class="mb-3 col-12 col-md-6">
                <label for="asset_id">{{
                    $t("purchases.driver")
                  }}</label>
                <input type="text" class="form-control" v-model="driver">
              </div>

              <!-- Plate Number -->
              <div class="mb-3 col-12 col-md-6">
                <label for="asset_id">{{
                    $t("purchases.plate_num")
                  }}</label>
                <input type="text" class="form-control" v-model="plate_num">
              </div>
            </div>

              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="asset_id">{{
                      $t("purchases.transfer_items")
                    }}</label>
                </div>

                <div class="col">
                  <button type="button" @click="transfer_items.push({})" style="float: inline-end;" class="btn">
                    <span class="bx bxs-plus-circle fa-2x" style="color: #2a3042 !important"></span>
                  </button>
                </div>
              </div>
              <div v-for="(item,index) in transfer_items" :key="item"
                   class="d-flex justify-content-between flex-column border-bottom mt-2 pb-2">

                <div class="d-flex justify-content-between align-items-center">

                  <!-- Product   -->
                  <div>
                    <label for="asset_id">{{
                        $t("purchases.product")
                      }}</label>
                    <AutoComplete
                        v-model="item.product"
                        :suggestions="matchedProducts"
                        @complete="searchProduct($event.query)"
                        :complete-on-focus="true"
                        :optionLabel="showProductsLabel"
                        style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                    />
                  </div>

                  <!-- Quantity -->
                  <div>
                    <label for="asset_id">{{
                        $t("purchases.qty")
                      }} {{item?.product ? "/ "+(item?.product?.qty - item?.product?.outgoing_sale - item?.product?.outgoing_transfer) : ""}}</label>
                    <input type="number"
                           :max="item?.product?.qty - item?.product?.outgoing_sale - item?.product?.outgoing_transfer"
                           @input="item.qty > (item?.product?.qty - item?.product?.outgoing_sale - item?.product?.outgoing_transfer) ? item.qty = (item?.product?.qty - item?.product?.outgoing_sale - item?.product?.outgoing_transfer) : item.qty = item.qty"
                           v-model="item.qty" class="form-control mb-2 mx-1"
                           :placeholder="$t('purchases.qty')" />
                  </div>


                  <button type="button" @click="transfer_items.splice(index,1)" class="btn btn-danger">
                    <span class="bx bxs-trash" style="color: #fff !important"></span>
                  </button>
                </div>
              </div>


          </div>
          <div class="card-footer">
            <button class="btn btn-primary mx-1" type="submit" href="javascript: void(0);">
              {{
                $t("inventories.incoming_full.submit") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>


  <div class="modal fade" id="review-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="doReview()">

          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("inventories.review") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    style="margin: 0">

            </button>
          </div>

          <div class="modal-body">

            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-2">
              <thead>
              <tr class="text-light text-center" style="background-color: #2a3042 !important">
                <th class="text-start" scope="col">
                  {{ $t("inventories.incoming_full.order_details.no") }}
                </th>
                <th class="text-start" scope="col">
                  {{ $t("inventories.incoming_full.order_details.product") }}
                </th>
                <th class="text-start" scope="col">
                  {{ $t("inventories.incoming_full.order_details.quantity") }}
                </th>
                <th class="text-start" scope="col">
                  {{ $t("inventories.existing_qty") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(detail, index) in stock" :key="detail">
                <td>{{ index + 1 }}</td>
                <td>{{ $i18n.locale == "ar"
                    ? detail?.name
                    : detail?.name_en }}</td>
                <td>{{ detail?.qty }}</td>

                <td>
                  <input type="number" min="0" @input="detail.received_quantity>detail.qty ? (detail.received_quantity = parseFloat(detail.qty)) : (detail.x=0)" class="form-control" v-model="detail.received_quantity">
                </td>
              </tr>
              </tbody>
            </table>


          </div>
          <div class="card-footer">
            <button class="btn btn-primary mx-1" type="submit" href="javascript: void(0);">
              {{
                $t("inventories.incoming_full.submit") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>


</template>

<style scoped>

</style>
