<script>

import {Dropdown, Modal} from 'bootstrap'
import CurrencyInput from "@/components/CurrencyInput";
export default {
  page: {
    meta: [
      {
        name: "description",

      },
    ],
  },
  props: ["methods"],
  components: {
    CurrencyInput

  },
  data() {
    return {
      filter: "incoming",
      detailModal: '',
      good_received_modal: '',
      handle_loss: '',
      page: 1,
      limit: 20,
      tot_pages: 0,
      dropdown: null,
      searchModel: "",
      incoming_items: [],
      items: [],
      landed_costs: [],
      selectedItem:null,
      loss_reason: [
        { id: 1, name: "تلف", name_en: "Damage" },
        { id: 2, name: " سرقة", name_en: "Theft" },
        { id: 3, name: "أخري", name_en: "other" },
      ],
    };
  },
  methods: {
    async showDetails(app) {
      this.selectedItem = Object.assign({},app)
      this.items = await this.getDetails(app.type,app.id)
      this.detailModal.show();

      // this.popup.modal(data);
    },
    async preGoodReceived(app) {
      this.selectedItem = app
      this.items = await this.getDetails(app.type,app.id)

      this.good_received_modal.show();

      this.items.forEach(item=>{
        item.shared_landed_cost = (parseFloat(item.unit_price) / this.totalOrderCost) * this.totalLandedCost
      })
      console.log("this.items",this.items)
      console.log("this.totalOrderCost",this.totalOrderCost)
      console.log("this.totalLandedCost",this.totalLandedCost)
    },
    getDetails(type,id){
      return new Promise(resolve => {
        this.http.post("inventories/get-incoming-details",{
          type,
          id
        }).then(resp=>{
          this.selectedItem.currency = resp.currency
          this.selectedItem.functional_currency = resp.functional_currency
          this.landed_costs = resp.landed_costs
          resolve(resp.data)
        })
      })
    },
    goodReceived(){
      console.log("items",this.items)
      console.log("selectedItem",this.selectedItem)    
      this.http.post("inventories/good-received",{
        order:{
          id : this.selectedItem.id,
          type : this.selectedItem.type,
          handle_loss : this.selectedItem.handle_loss,
          total_landed_cost_loss : this.totalLandedCostLoss,
          supplier_charge_order_loss : this.selectedItem.supplier_charge_order_loss,
          supplier_charge_landed_cost_loss : this.selectedItem.supplier_charge_landed_cost_loss,
          suggested_gr_time : this.selectedItem.suggested_gr_time
        },
        items:this.items.map(item=>{
          return {
            id:item.id,
            shared_landed_cost:item.shared_landed_cost,
            qty:parseFloat(item.qty),
            unit_price:parseFloat(item.unit_price),
            vat:parseFloat(item.vat),
            received_quantity:item.received_quantity,
            loss_reason:item.loss_reason,
            record_id:item.record_id,
            comment:item.comment
          }
        })
      })
        .then(()=>{   
          this.get(this.page)
          this.good_received_modal.hide()
        })
    },
    get(page) {
      console.log(page);
      this.http
          .post("inventories/incoming-items", {
            filter: this.filter,
            search: (this.searchModel ? this.searchModel : ""),
            limit: this.limit,
            page,
            inventory_id: this.$route.params.id
          })
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.incoming_items = res.data;
          });
    },
  },
  computed: {
    lossExists(){
      return this.items?.filter(item=>item.received_quantity!=null && item.received_quantity != parseFloat(item.qty)).length > 0
    },
    totalLandedCost(){
      return this.landed_costs.reduce((sum,a)=>sum+parseFloat(a.value),0)
    },
    totalOrderCost(){
      return this.items.reduce((sum,a)=>sum + parseFloat(a.unit_price) * parseFloat(a.qty),0)
    },
    totalVATCost(){
      return this.items.reduce((sum,a)=>sum + parseFloat(a.vat) * parseFloat(a.qty),0)
    },
    totalOrderLoss(){
      return this.items.filter(item=>item.received_quantity!=null && item.received_quantity != parseFloat(item.qty)).reduce((sum,a)=>sum + parseFloat(a.unit_price) * ( parseFloat(a.qty) - a.received_quantity),0)
    },
    totalVATLoss(){
      return this.items.filter(item=>item.received_quantity!=null && item.received_quantity != parseFloat(item.qty)).reduce((sum,a)=>sum +  parseFloat(a.vat) * ( parseFloat(a.qty) - a.received_quantity),0)
    },
    totalLandedCostLoss(){
      return this.items.filter(item=>item.received_quantity!=null && item.received_quantity != parseFloat(item.qty)).reduce((sum,a)=>sum + (parseFloat(a.shared_landed_cost)) * ( parseFloat(a.qty) - a.received_quantity),0)
    }
  },
  mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    this.methods.get_incoming = this.get
    this.dropdown = new Dropdown(".dropdown-toggle.filter-incoming")
    this.good_received_modal = new Modal(`#good-received`)
    this.detailModal = new Modal(`#incoming-good-details`)
    this.get(1);

  },

  created() {

  }
};
</script>

<template>

  <div class="d-flex justify-content-between">
    <div class="search-box chat-search-box w-25">
      <div class="position-relative">
        <input @input="get(1)" type="search" class="form-control fa-lg text-light"
               :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
               style="background-color: #2a3042 !important" />
        <i class="bx bx-search-alt search-icon text-light"></i>
      </div>
    </div>

   <div class="d-flex">
     <div class="">
       <div>
         <div class="position-relative">
           <input type="text" :value="$t(('inventories.'+filter))" class="form-control" readonly>
         </div>
       </div>
     </div>
     <div class="">
       <div class="dropdown">
         <button class="btn btn-light dropdown-toggle filter-incoming" @click="dropdown.toggle" data-bs-toggle="dropdown" >
           <span class="fa fa-filter"></span>
         </button>
         <ul class="dropdown-menu" style="z-index:100">
           <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='all'; get(page)">{{$t("inventories.all")}}</a></li>
           <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='incoming'; get(page)">{{$t("inventories.incoming")}}</a></li>
           <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='received'; get(page)">{{$t("inventories.received")}}</a></li>
           <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='purchases_only'; get(page)">{{$t("inventories.purchases_only")}}</a></li>
           <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='inventory_transfers_only'; get(page)">{{$t("inventories.inventory_transfers_only")}}</a></li>
           <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='credit_notes_only'; get(page)">{{$t("inventories.credit_notes_only")}}</a></li>
           <li><a href="javascript: void(0)" class="dropdown-item" @click="filter='exceeded_schedule_only'; get(page)">{{$t("inventories.exceeded_schedule_only")}}</a></li>
         </ul>
       </div>
     </div>
   </div>

  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
    <thead>
    <tr class="text-light text-center" style="background-color: #2a3042 !important">
      <th scope="col">
        {{ $t("inventories.incoming_full.number") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.code") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.type") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.source") }}
      </th>
      <th scope="col">
        {{ $t("purchases.driver") }}
      </th>
      <th scope="col">
        {{ $t("purchases.plate_num") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.scheduled_time") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.delivery_time") }}
      </th>
      <th scope="col">
        {{ $t("purchases.created") }}
      </th>
      <th scope="col">
        {{ $t("inventories.incoming_full.actions") }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(app, index) in incoming_items" :key="app" class="text-center">

      <td>{{ (index+1) }}</td>
      <td>{{ app?.code }}</td>
      <td>{{ $t("inventories."+app?.type) }}</td>
      <td>{{ $i18n.locale == "ar" ? app?.source : app?.source_en }}</td>
      <td>{{ app?.driver }}</td>
      <td>{{ app?.plate_num }}</td>
      <td>{{ app?.suggested_gr_time ? moment(app?.suggested_gr_time).format("YYYY-MM-DD") : $t("inventories.incoming_full.no_scheduled_time") }}</td>
      <td>{{ app?.gr_time ? moment(app?.gr_time).format("YYYY-MM-DD") : $t("inventories.incoming_full.not_delivered") }}</td>
      <td :title="moment(app?.created).format('h:mm a')">{{ moment(app?.created).format("YYYY-MM-DD") }}</td>

      <td class="d-flex justify-content-start">

        <a class="btn btn-primary mx-1" style="font-size:80%" @click="showDetails(app)" href="javascript: void(0);" role="button">
          {{
            $t("inventories.incoming_full.details") }}
        </a>

        <a class="btn btn-primary mx-1" style="font-size:80%;white-space:nowrap" v-if="!app?.gr_time" @click="preGoodReceived(app)" href="javascript: void(0);" role="button">
          {{
            $t("inventories.incoming_full.gr") }}
        </a>

      </td>
    </tr>
    </tbody>
  </table>

  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages> 1">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>


  <div class="modal fade" id="good-received" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog" style="max-width: 1024px">
      <div class="modal-content">
        <form @submit.prevent="goodReceived()">

          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("inventories.incoming_full.order_details.page_title") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    style="margin: 0">

            </button>
          </div>

          <div class="modal-body">

            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-2">
              <thead>
              <tr class="text-light" style="background-color: #2a3042 !important">
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.no") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.product") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.quantity") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.received_quantity") }}
                </th>

                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.loess_reasons") }}
                </th>

                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.comment") }}
                </th>
                <th scope="col" colspan="2">
                  {{ $t("inventories.cost_of_loss") }}

                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(detail, index) in items" :key="detail">
                <td>{{ index + 1 }}</td>
                <td>{{ $i18n.locale == "ar"
                    ? detail?.name
                    : detail?.name_en }}</td>
                <td>{{ parseFloat(detail.qty) }}</td>

                <td>
                  <input type="number" :max="parseFloat(detail.qty)" min="0" @input="detail.received_quantity>detail.qty ? (detail.received_quantity = parseFloat(detail.qty)) : (detail.x=0)" class="form-control" v-model="detail.received_quantity">
                </td>
                <td style="text-align: center;">
                  <select v-model="detail.loss_reason" :disabled="detail?.qty == detail?.received_quantity" class="form-control" id="">
                    <option v-for="reason in loss_reason" :key="reason" :value="reason.id">
                      {{
                        $i18n.locale == "ar"
                            ? reason.name
                            : reason.name_en
                      }}
                    </option>
                  </select>
                </td>
                <td>
                  <input type="text" class="form-control" v-model="detail.comment">
                </td>

                <td>
                    <span v-if="detail.received_quantity && detail.qty != detail.received_quantity">
                      {{parseFloat((parseFloat(detail.unit_price)  * (parseFloat(detail.qty) - parseFloat(detail.received_quantity))).toFixed(2))?.toLocaleString()}}
                    </span>
                </td>
              </tr>
              </tbody>
            </table>

            <h5 v-if="lossExists && selectedItem.type!='purchase'" style="font-size:115%" class="mt-4">{{$t("inventories.total_landed_cost_loss")}}: <b>{{processCurrency(selectedItem?.functional_currency,totalLandedCostLoss,true)}}</b></h5>

            <div v-if="lossExists && selectedItem.type=='purchase'">
              <div class="mb-1 mb-sm-3">
                <label class="form-label">{{$t("inventories.handle_loss")}}</label>
                <div class="form-check mb-2 mt-2">
                  <input class="form-check-input" type="radio" v-model="selectedItem.handle_loss" id="handle0" value="reorder">
                  <label class="form-check-label" for="handle0">{{$t("inventories.reorder")}}</label>
                </div>
                <div class="form-check mb-2 mt-2">
                  <input class="form-check-input" type="radio" v-model="selectedItem.handle_loss" id="handle1" value="backorder">
                  <label class="form-check-label" for="handle1">{{$t("inventories.backorder")}}</label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" v-model="selectedItem.handle_loss" id="handle2" value="partially_charge_supplier">
                  <label class="form-check-label" for="handle2">{{$t("inventories.partially_charge_supplier")}}</label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" v-model="selectedItem.handle_loss" id="handle3" value="fully_charge_supplier">
                  <label class="form-check-label" for="handle3">{{$t("inventories.fully_charge_supplier")}}</label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" v-model="selectedItem.handle_loss" id="handle4" value="bear_loss">
                  <label class="form-check-label" for="handle4">{{$t("inventories.bear_loss")}}</label>
                </div>
              </div>
              <hr>
              <div v-if="selectedItem.handle_loss=='backorder' || selectedItem.handle_loss=='reorder'">
                <label class="form-label">{{$t("purchases.suggested_gr_time")}}</label>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4">
                    <input type="date" class="form-control" v-model="selectedItem.suggested_gr_time">
                  </div>
                </div>
              </div>
              <div v-else-if="selectedItem.handle_loss && selectedItem.handle_loss!='bear_loss'">
                <h5 style="font-size:115%">{{$t("inventories.total_order_loss")}}: <b>{{processCurrency(selectedItem?.currency,totalOrderLoss,true)}} {{totalVATLoss?'+('+totalVATLoss?.toLocaleString()+' '+$t("purchases.vat_value")+')':''}}</b></h5>
                <div v-if="selectedItem.handle_loss=='partially_charge_supplier'">
                  <div class="my-1 my-sm-3">
                    <label class="form-label">{{$t("inventories.supplier_charge_order_loss")}} ({{selectedItem?.currency?.symbol ? selectedItem?.currency?.symbol : selectedItem?.currency?.short_name}})</label>
                    <currency-input  v-model="selectedItem.supplier_charge_order_loss" :max="parseFloat(totalOrderLoss)" class="form-control"></currency-input>
                    <h6 style="font-size:105%" class="mt-2">{{selectedItem.supplier_charge_order_loss?.toLocaleString()}} + {{(selectedItem.supplier_charge_order_loss*(totalVATLoss? (totalVATLoss/totalOrderLoss):0))?.toLocaleString()}} = <b><u>{{processCurrency(selectedItem?.currency,(selectedItem.supplier_charge_order_loss*(totalVATLoss? (totalVATLoss/totalOrderLoss):0) + parseFloat(selectedItem.supplier_charge_order_loss)),true)}}</u></b></h6>
                  </div>
                  <hr>
                </div>
                <h5 style="font-size:115%" class="mt-4">{{$t("inventories.total_landed_cost_loss")}}: <b>{{processCurrency(selectedItem?.functional_currency,totalLandedCostLoss,true)}}</b></h5>

                <div class="mb-1 mb-sm-3">
                  <label class="form-label">{{$t("inventories.supplier_charge_landed_cost_loss")}}</label>
                  <currency-input :disabled="totalLandedCostLoss==0" v-model="selectedItem.supplier_charge_landed_cost_loss" :max="totalLandedCostLoss" class="form-control"></currency-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-primary mx-1" type="submit" href="javascript: void(0);">
              {{
                $t("inventories.incoming_full.submit") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="incoming-good-details" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog" style="max-width: 1024px">
      <div class="modal-content">

          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("inventories.incoming_full.order_details.page_title") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    style="margin: 0">

            </button>
          </div>

          <div class="modal-body">

            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-2">
              <thead>
              <tr class="text-light" style="background-color: #2a3042 !important">
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.no") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.product") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.quantity") }}
                </th>
                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.received_quantity") }}
                </th>

                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.loess_reasons") }}
                </th>

                <th scope="col">
                  {{ $t("inventories.incoming_full.order_details.comment") }}
                </th>
                <th scope="col" colspan="2">
                  {{ $t("inventories.cost_of_loss") }}

                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(detail, index) in items" :key="detail">
                <td>{{ index + 1 }}</td>
                <td>{{ $i18n.locale == "ar"
                    ? detail?.name
                    : detail?.name_en }}</td>
                <td>{{ parseFloat(detail?.qty) }}</td>
                <td>{{detail?.received_quantity ? parseFloat(detail?.received_quantity) : '-' }}</td>
                <td>{{ detail?.loss_reason ? detail?.loss_reason : '-'}}</td>
                <td>{{ detail?.comment ? detail?.comment : '-'}}</td>
                <td>
                    <span v-if="detail.received_quantity && detail.qty != detail.received_quantity">
                      {{parseFloat((parseFloat(detail.unit_price)  * (parseFloat(detail.qty) - parseFloat(detail.received_quantity))).toFixed(2))?.toLocaleString()}}
                    </span>
                  <span v-else>-</span>
                </td>
              </tr>
              </tbody>
            </table>

            <div v-if="lossExists">
              <div class="mb-1 mb-sm-3">
                <label class="form-label">{{$t("inventories.handle_loss")}}: {{selectedItem.handle_loss}}</label>
              </div>
              <hr>
              <div v-if="selectedItem.handle_loss=='backorder' || selectedItem.handle_loss=='reorder'">
                <label class="form-label">{{$t("purchases.suggested_gr_time")}}: {{selectedItem.suggested_gr_time}}</label>
              </div>
              <div v-else-if="selectedItem.handle_loss && selectedItem.handle_loss!='bear_loss'">
                <h5 style="font-size:115%">{{$t("inventories.total_order_loss")}}: <b>{{processCurrency(selectedItem?.currency,totalOrderLoss,true)}} {{totalVATLoss?'+('+totalVATLoss?.toLocaleString()+' '+$t("purchases.vat_value")+')':''}}</b></h5>
                <div v-if="selectedItem.handle_loss=='partially_charge_supplier'">
                  <div class="my-1 my-sm-3">
                    <label class="form-label">{{$t("inventories.supplier_charge_order_loss")}}: {{processCurrency(selectedItem?.currency,selectedItem?.supplier_charge_order_loss)}}</label>
                    <h6 style="font-size:105%" class="mt-2">{{selectedItem.supplier_charge_order_loss?.toLocaleString()}} + {{(selectedItem.supplier_charge_order_loss*totalVATLoss/totalOrderLoss)?.toLocaleString()}} = <b><u>{{processCurrency(selectedItem?.currency,(selectedItem.supplier_charge_order_loss*totalVATLoss/totalOrderLoss + parseFloat(selectedItem.supplier_charge_order_loss)),true)}}</u></b></h6>
                  </div>
                  <hr>
                </div>
                <h5 style="font-size:115%" class="mt-4">{{$t("inventories.total_landed_cost_loss")}}: <b>{{processCurrency(selectedItem?.functional_currency,totalLandedCostLoss,true)}}</b></h5>

                <div class="mb-1 mb-sm-3">
                  <label class="form-label">{{$t("inventories.supplier_charge_landed_cost_loss")}}: {{selectedItem.supplier_charge_landed_cost_loss}}</label>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>

</template>

<style scoped>
.form-check{
  text-align:unset !important;
}
.form-check .form-check-input{
  float:unset !important;

}
[dir=rtl] .form-check .form-check-input{
  margin-left: .5em;
}
[dir=ltr] .form-check .form-check-input{
  margin-right: .5em;
}
</style>
